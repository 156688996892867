
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isNumberControl,
} from "@jsonforms/core";
import { defineComponent } from "vue";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import { useVanillaControl } from "@jsonforms/vue-vanilla";
import ControlWrapper from "@/core/json-forms/controls/ControlWrapper.vue";
import RendererConfigurations from "@/core/json-forms/renderer-configurations";

const controlRenderer = defineComponent({
  name: RendererConfigurations.NumberControlRenderer.name,
  components: {
    ControlWrapper,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: any) {
    return useVanillaControl(useJsonFormsControl(props), (target) => Number(target.value));
  },
  computed: {
    RendererConfigurations() {
      return RendererConfigurations;
    },
    step(): number {
      const options: any = this.appliedOptions;
      return options.step ?? 0.1;
    },
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(5, isNumberControl),
};
