
import {
  composePaths,
  createDefaultValue,
  JsonFormsRendererRegistryEntry,
  rankWith,
  ControlElement,
  schemaTypeIs,
} from "@jsonforms/core";
import {
  DispatchRenderer,
  rendererProps,
  useJsonFormsArrayControl,
} from "@jsonforms/vue";
import { defineComponent } from "vue";
import { useVanillaArrayControl } from "@jsonforms/vue-vanilla";
import ArrayListElement from "@/core/json-forms/controls/ArrayListElement.vue";
import RendererConfigurations from "@/core/json-forms/renderer-configurations";

const controlRenderer = defineComponent({
  name: RendererConfigurations.ArrayListRenderer.name,
  components: {
    ArrayListElement,
    DispatchRenderer,
  },
  props: {
    outputList: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    userDefinedVariables: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    systemVariables: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    metaoutputList: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    stepIdx: Number,
    ...rendererProps<ControlElement>(),
  },
  emits: ["handleClickEvent", "expressionDrawer"],
  data() {
    return {
      toggle: true,
    };
  },
  setup(props: any) {
    return useVanillaArrayControl(useJsonFormsArrayControl(props));
  },
  computed: {
    RendererConfigurations() {
      return RendererConfigurations;
    },
    noData(): boolean {
      return !this.control.data || this.control.data.length === 0;
    },
  },
  methods: {
    composePaths,
    createDefaultValue,
    addButtonClick() {
      this.addItem(
        this.control.path,
        createDefaultValue(this.control.schema),
      )();
      this.toggle = true;
    },
    handleClickEvent(visible, controlId) {
      this.$emit("handleClickEvent", visible, controlId);
    },
    expressionDrawer(visible) {
      this.$emit("expressionDrawer", visible);
    },
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(15, schemaTypeIs("array")),
};
