import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["data-renderer-id"]
const _hoisted_2 = {
  key: 0,
  class: "color-red"
}
const _hoisted_3 = { class: "accordion-collapse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!
  const _component_array_list_element = _resolveComponent("array-list-element")!

  return (_ctx.control.visible)
    ? (_openBlock(), _createElementBlock("fieldset", {
        key: 0,
        class: _normalizeClass(_ctx.styles.arrayList.root),
        style: {"border-left":"3px solid #e3e3e3"},
        "data-renderer-id": _ctx.RendererConfigurations.ArrayListRenderer.name
      }, [
        _createElementVNode("legend", {
          class: _normalizeClass([_ctx.styles.arrayList.legend, "d-flex flex-row justify-content-between margin-0 array-list-legend-custom"])
        }, [
          _createElementVNode("label", {
            class: "d-flex flex-row align-items-center font-size-14 cursor-pointer full-width pd-l-5",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle = !_ctx.toggle))
          }, [
            _createTextVNode(_toDisplayString(_ctx.control.label), 1),
            (_ctx.control.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("button", {
            class: "btn btn-link",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addButtonClick && _ctx.addButtonClick(...args))),
            type: "button"
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "plus",
              size: "xs"
            })
          ])
        ], 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.control.data, (element, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${_ctx.control.path }-${index}`,
            class: _normalizeClass([_ctx.styles.arrayList.itemWrapper, "accordion"])
          }, [
            _withDirectives(_createVNode(_component_array_list_element, {
              delete: _ctx.removeItems(_ctx.control.path, [index]),
              label: `${_ctx.control.label} - ${index+1}`,
              styles: _ctx.styles
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_dispatch_renderer, {
                    schema: _ctx.control.schema,
                    uischema: _ctx.childUiSchema,
                    path: _ctx.composePaths(_ctx.control.path, `${index}`),
                    onHandleClickEvent: _ctx.handleClickEvent,
                    onExpressionDrawer: _ctx.expressionDrawer,
                    enabled: _ctx.control.enabled,
                    stepIdx: _ctx.stepIdx,
                    outputList: _ctx.outputList,
                    metaoutputList: _ctx.metaoutputList,
                    userDefinedVariables: _ctx.userDefinedVariables,
                    systemVariables: _ctx.systemVariables,
                    renderers: _ctx.control.renderers,
                    cells: _ctx.control.cells,
                    class: "accordion-body pl-20",
                    style: {"border-left":"4px solid #f3f3f3 !important"}
                  }, null, 8, ["schema", "uischema", "path", "onHandleClickEvent", "onExpressionDrawer", "enabled", "stepIdx", "outputList", "metaoutputList", "userDefinedVariables", "systemVariables", "renderers", "cells"])
                ])
              ]),
              _: 2
            }, 1032, ["delete", "label", "styles"]), [
              [_vShow, _ctx.toggle]
            ])
          ], 2))
        }), 128))
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}