import {
  and,
  isBooleanControl, isDateControl, isDateTimeControl, isEnumControl,
  isIntegerControl,
  isLayout, isMultiLineControl,
  isNumberControl, isOneOfEnumControl,
  isStringControl, isTimeControl,
  rankWith,
  schemaTypeIs,
} from "@jsonforms/core";

const RendererConfigurations = {
  BooleanSwitchRenderer: {
    name: "boolean-switch-renderer",
    tester: rankWith(2, isBooleanControl),
  },
  StringControlRenderer: {
    name: "string-control-renderer",
    tester: rankWith(2, isStringControl),
  },
  DefaultStringControlRenderer: {
    name: "default-string-control-renderer",
    tester: rankWith(3, isStringControl),
  },
  DefaultLayoutRenderer: {
    name: "layout-renderer",
    tester: rankWith(2, isLayout),
  },
  ArrayListRenderer: {
    name: "array-list-renderer",
    tester: rankWith(3, schemaTypeIs("array")),
  },
  MultiStringControlRenderer: {
    name: "multi-string-control-renderer",
    tester: rankWith(3, and(isStringControl, isMultiLineControl)),
  },
  EnumControlRenderer: {
    name: "enum-control-renderer",
    tester: rankWith(3, isEnumControl),
  },
  NumberControlRenderer: {
    name: "number-control-renderer",
    tester: rankWith(3, isNumberControl),
  },
  IntegerControlRenderer: {
    name: "integer-control-renderer",
    tester: rankWith(3, isIntegerControl),
  },
  EnumOneOfControlRenderer: {
    name: "enum-oneof-control-renderer",
    tester: rankWith(3, isOneOfEnumControl),
  },
  DateControlRenderer: {
    name: "date-control-renderer",
    tester: rankWith(3, isDateControl),
  },
  DateTimeControlRenderer: {
    name: "datetime-control-renderer",
    tester: rankWith(3, isDateTimeControl),
  },
  TimeControlRenderer: {
    name: "time-control-renderer",
    tester: rankWith(3, isTimeControl),
  },
};

export default RendererConfigurations;
