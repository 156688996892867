import { PropType } from "vue";

export function divideUIElementsByRequired(jsonSchema: any, uiSchema: any)
  : { requiredElements: any[], optionalElements: any[] } {
  const requiredElements = [];
  const optionalElements = [];
  const requiredProperties = jsonSchema.required ?? [];

  uiSchema.elements.forEach((element) => {
    if (requiredProperties.some((property) => element?.scope?.endsWith(property))) {
      requiredElements.push(element);
    } else {
      optionalElements.push(element);
    }
  });

  return {
    requiredElements,
    optionalElements,
  };
}

export function getAllElements(uiSchema: any): any[] {
  const allElements = [];
  uiSchema.elements.forEach((element) => {
    allElements.push(element);
  });
  return allElements;
}

export declare type CompType<S, _V> = PropType<S>;
