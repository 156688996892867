import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74615300"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-renderer-id"]
const _hoisted_2 = ["id", "checked", "disabled", "autofocus", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_wrapper = _resolveComponent("control-wrapper")!

  return (_openBlock(), _createBlock(_component_control_wrapper, _mergeProps(_ctx.controlWrapper, {
    styles: _ctx.styles,
    isFocused: _ctx.isFocused,
    appliedOptions: _ctx.appliedOptions
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "form-check form-switch form-switch-lg",
        "data-renderer-id": _ctx.RendererConfigurations.BooleanSwitchRenderer.name
      }, [
        _createElementVNode("input", {
          type: "checkbox",
          class: _normalizeClass(["form-check-input", {'required-border': _ctx.control.data === undefined && _ctx.control.required}]),
          id: _ctx.control.id + '-input',
          checked: !!_ctx.control.data,
          disabled: !_ctx.control.enabled,
          autofocus: _ctx.appliedOptions.focus,
          placeholder: _ctx.appliedOptions.placeholder,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = false))
        }, null, 42, _hoisted_2)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 16, ["styles", "isFocused", "appliedOptions"]))
}