import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "color-red"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "color-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.description)
      ? (_openBlock(), _createBlock(_component_ElTooltip, {
          key: 0,
          effect: "custom",
          content: _ctx.description,
          placement: "left"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(['scrollable-tooltip-content', { 'overflow-auto': _ctx.isOverflowing }])
            }, _toDisplayString(_ctx.description), 3)
          ]),
          default: _withCtx(() => [
            _createElementVNode("label", {
              for: _ctx.id + '-input',
              class: _normalizeClass([_ctx.styles.control.label, "font-size-14"])
            }, [
              _createTextVNode(_toDisplayString(_ctx.label), 1),
              (_ctx.required)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
                : _createCommentVNode("", true)
            ], 10, _hoisted_1)
          ]),
          _: 1
        }, 8, ["content"]))
      : (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: _ctx.id + '-input',
          class: _normalizeClass([_ctx.styles.control.label, "font-size-14"])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
            : _createCommentVNode("", true)
        ], 10, _hoisted_3)),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.styles.control.wrapper)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}