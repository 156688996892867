
import { defineComponent } from "vue";
import { CompType } from "@/core/json-forms/utils";
import { classes, Styles } from "@/core/json-forms/styles";

const listItem = defineComponent({
  name: "array-list-element",
  props: {
    initiallyExpanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    delete: {
      required: false,
      type: Function,
      default: undefined,
    },
    styles: {
      required: true,
      type: Object as CompType<Styles, ObjectConstructor>,
    },
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
    };
  },
  computed: {
    contentClasses(): string {
      return classes`${this.styles.arrayList.itemContent} ${this.expanded
      && this.styles.arrayList.itemExpanded}`;
    },
    toolbarClasses(): string {
      return classes`${this.styles.arrayList.itemToolbar} ${this.expanded
      && this.styles.arrayList.itemExpanded}`;
    },
  },
  methods: {
    expandClicked(): void {
      this.expanded = !this.expanded;
    },
    deleteClicked(event: Event): void {
      event.stopPropagation();
      // eslint-disable-next-line no-unused-expressions
      this.delete?.();
    },
  },
});

export default listItem;
