
import {
  isLayout,
  JsonFormsRendererRegistryEntry,
  Layout,
  rankWith,
} from "@jsonforms/core";
import { defineComponent } from "vue";
import {
  DispatchRenderer,
  rendererProps,
  useJsonFormsLayout,
} from "@jsonforms/vue";
import { useVanillaLayout } from "@jsonforms/vue-vanilla";
import { divideUIElementsByRequired } from "@/core/json-forms/utils";
import RendererConfigurations from "@/core/json-forms/renderer-configurations";

const layoutRenderer = defineComponent({
  name: RendererConfigurations.DefaultLayoutRenderer.name,
  components: {
    DispatchRenderer,
  },
  props: {
    outputList: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    userDefinedVariables: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    systemVariables: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    expressions: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    metaoutputList: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    showOtherInputs: {
      required: false,
      type: Boolean,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: true,
    },
    stepIdx: {
      required: true,
      type: Number,
    },
    ...rendererProps<Layout>(),
  },
  emits: ["update", "updatePopUpOpen", "handleClickEvent", "expressionDrawer"],
  setup(props: any) {
    return useVanillaLayout(useJsonFormsLayout(props));
  },
  methods: {
    updatePopUpOpen(isOpen) {
      this.$emit("updatePopUpOpen", isOpen);
    },
    handleClickEvent(visible, controlId) {
      this.$emit("handleClickEvent", visible, controlId);
    },
    expressionDrawer(visible) {
      this.$emit("expressionDrawer", visible);
    },
  },
  computed: {
    RendererConfigurations() {
      return RendererConfigurations;
    },
    layoutClassObject(): any {
      return this.layout.direction === "row"
        ? this.styles.horizontalLayout
        : this.styles.verticalLayout;
    },
    dividedElements(): any {
      return divideUIElementsByRequired(this.layout.schema, this.layout.uischema);
    },
  },
});

export default layoutRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: layoutRenderer,
  tester: rankWith(1, isLayout),
};
