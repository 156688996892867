import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "value", "disabled", "autofocus", "data-renderer-id", "data-id"]
const _hoisted_2 = ["value", "label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_wrapper = _resolveComponent("control-wrapper")!

  return (_openBlock(), _createBlock(_component_control_wrapper, _mergeProps(_ctx.controlWrapper, {
    styles: _ctx.styles,
    isFocused: _ctx.isFocused,
    appliedOptions: _ctx.appliedOptions
  }), {
    default: _withCtx(() => [
      _createElementVNode("select", {
        id: _ctx.control.id + '-select',
        class: _normalizeClass([[_ctx.styles.control.select,{'required-border': !_ctx.control.data && _ctx.control.required}], "small-input-field"]),
        value: _ctx.control.data,
        disabled: !_ctx.control.enabled,
        autofocus: _ctx.appliedOptions.focus,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = false)),
        "data-renderer-id": _ctx.RendererConfigurations.EnumControlRenderer.name,
        "data-id": _ctx.control.id.replace('#/','') + '-select'
      }, [
        _createElementVNode("option", {
          value: "",
          key: "empty",
          class: _normalizeClass(_ctx.styles.control.option)
        }, null, 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.control.options, (optionElement) => {
          return (_openBlock(), _createElementBlock("option", {
            key: optionElement.value,
            value: optionElement.value,
            label: optionElement.label,
            class: _normalizeClass(_ctx.styles.control.option)
          }, null, 10, _hoisted_2))
        }), 128))
      ], 42, _hoisted_1)
    ]),
    _: 1
  }, 16, ["styles", "isFocused", "appliedOptions"]))
}