
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isStringControl,
  isMultiLineControl,
  and,
} from "@jsonforms/core";
import { defineComponent } from "vue";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import { useVanillaControl } from "@jsonforms/vue-vanilla";
import ControlWrapper from "@/core/json-forms/controls/ControlWrapper.vue";
import RendererConfigurations from "@/core/json-forms/renderer-configurations";

const controlRenderer = defineComponent({
  name: RendererConfigurations.MultiStringControlRenderer.name,
  computed: {
    RendererConfigurations() {
      return RendererConfigurations;
    },
  },
  components: {
    ControlWrapper,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: any) {
    return useVanillaControl(useJsonFormsControl(props));
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(5, and(isStringControl, isMultiLineControl)),
};
