
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isDateTimeControl,
} from "@jsonforms/core";
import { defineComponent } from "vue";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import { useVanillaControl } from "@jsonforms/vue-vanilla";
import ControlWrapper from "@/core/json-forms/controls/ControlWrapper.vue";
import RendererConfigurations from "@/core/json-forms/renderer-configurations";

const toISOString = (inputDateTime: string) => (inputDateTime === "" ? "" : `${inputDateTime}:00.000Z`);

const controlRenderer = defineComponent({
  name: RendererConfigurations.DateControlRenderer.name,
  components: {
    ControlWrapper,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: any) {
    return useVanillaControl(useJsonFormsControl(props), (target) => toISOString(target.value));
  },
  computed: {
    RendererConfigurations() {
      return RendererConfigurations;
    },
    dataTime(): string {
      return (this.control.data ?? "").substr(0, 16);
    },
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(5, isDateTimeControl),
};
