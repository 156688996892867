import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion-header font-size-14 pd-l-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.styles.arrayList.item)
  }, [
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.expandClicked && _ctx.expandClicked(...args))),
      class: _normalizeClass([_ctx.toolbarClasses, "margin-0"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.styles.arrayList.itemLabel, "d-flex flex-row align-items-center justify-content-between"])
      }, [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.label), 1),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteClicked && _ctx.deleteClicked(...args))),
          class: "btn btn-link",
          type: "button"
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "trash",
            size: "sm"
          })
        ])
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.contentClasses)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}