import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "value", "disabled", "autofocus", "placeholder", "data-renderer-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_wrapper = _resolveComponent("control-wrapper")!

  return (_openBlock(), _createBlock(_component_control_wrapper, _mergeProps(_ctx.controlWrapper, {
    styles: _ctx.styles,
    isFocused: _ctx.isFocused,
    appliedOptions: _ctx.appliedOptions
  }), {
    default: _withCtx(() => [
      _createElementVNode("input", {
        type: "time",
        id: _ctx.control.id + '-input',
        class: _normalizeClass([[_ctx.styles.control.input,{'required-border': !_ctx.control.data && _ctx.control.required}], "small-input-field"]),
        value: _ctx.control.data,
        disabled: !_ctx.control.enabled,
        autofocus: _ctx.appliedOptions.focus,
        placeholder: _ctx.appliedOptions.placeholder,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = false)),
        "data-renderer-id": _ctx.RendererConfigurations.TimeControlRenderer.name
      }, null, 42, _hoisted_1)
    ]),
    _: 1
  }, 16, ["styles", "isFocused", "appliedOptions"]))
}