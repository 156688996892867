import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-renderer-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!

  return (_ctx.layout.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.layoutClassObject.root),
        "data-renderer-id": _ctx.RendererConfigurations.DefaultLayoutRenderer.name
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dividedElements.requiredElements, (element, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${_ctx.layout.path}-${index}`,
            class: _normalizeClass(_ctx.layoutClassObject.item)
          }, [
            _createVNode(_component_dispatch_renderer, {
              schema: _ctx.layout.schema,
              uischema: element,
              path: _ctx.layout.path,
              enabled: _ctx.layout.enabled,
              onHandleClickEvent: _ctx.handleClickEvent,
              onExpressionDrawer: _ctx.expressionDrawer,
              renderers: _ctx.layout.renderers,
              cells: _ctx.layout.cells,
              stepIdx: _ctx.stepIdx,
              outputList: _ctx.outputList,
              metaoutputList: _ctx.metaoutputList,
              userDefinedVariables: _ctx.userDefinedVariables,
              systemVariables: _ctx.systemVariables,
              expressions: _ctx.expressions,
              showOtherInputs: _ctx.showOtherInputs,
              onUpdatePopUpOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePopUpOpen($event))),
              onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update')))
            }, null, 8, ["schema", "uischema", "path", "enabled", "onHandleClickEvent", "onExpressionDrawer", "renderers", "cells", "stepIdx", "outputList", "metaoutputList", "userDefinedVariables", "systemVariables", "expressions", "showOtherInputs"])
          ], 2))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dividedElements.optionalElements, (element, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${_ctx.layout.path}-${index}`,
            class: _normalizeClass(_ctx.layoutClassObject.item)
          }, [
            _createVNode(_component_dispatch_renderer, {
              schema: _ctx.layout.schema,
              uischema: element,
              path: _ctx.layout.path,
              enabled: _ctx.layout.enabled,
              onHandleClickEvent: _ctx.handleClickEvent,
              onExpressionDrawer: _ctx.expressionDrawer,
              renderers: _ctx.layout.renderers,
              cells: _ctx.layout.cells,
              stepIdx: _ctx.stepIdx,
              outputList: _ctx.outputList,
              metaoutputList: _ctx.metaoutputList,
              userDefinedVariables: _ctx.userDefinedVariables,
              systemVariables: _ctx.systemVariables,
              expressions: _ctx.expressions,
              showOtherInputs: _ctx.showOtherInputs,
              onUpdatePopUpOpen: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePopUpOpen($event))),
              onUpdate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update')))
            }, null, 8, ["schema", "uischema", "path", "enabled", "onHandleClickEvent", "onExpressionDrawer", "renderers", "cells", "stepIdx", "outputList", "metaoutputList", "userDefinedVariables", "systemVariables", "expressions", "showOtherInputs"])
          ], 2))
        }), 128))
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}