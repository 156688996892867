import { defaultStyles, mergeStyles, vanillaRenderers } from "@jsonforms/vue-vanilla";

import BooleanSwitchRenderer from "@/core/json-forms/controls/BooleanSwitchRenderer.vue";

import StringControlRenderer from "@/core/json-forms/controls/StringControlRenderer.vue";

import DefaultStringControlRenderer from "@/core/json-forms/controls/DefaultStringControlRenderer.vue";

import DefaultLayoutRenderer from "@/core/json-forms/controls/DefaultLayoutRenderer.vue";

import ArrayListRenderer from "@/core/json-forms/controls/ArrayListRenderer.vue";

import EnumControlRenderer from "@/core/json-forms/controls/EnumControlRenderer.vue";

import MultiStringControlRenderer from "@/core/json-forms/controls/MultiStringControlRenderer.vue";

import NumberControlRenderer from "@/core/json-forms/controls/NumberControlRenderer.vue";

import IntegerControlRenderer from "@/core/json-forms/controls/IntegerControlRenderer.vue";

import EnumOneOfControlRenderer from "@/core/json-forms/controls/EnumOneOfControlRenderer.vue";

import DateControlRenderer from "@/core/json-forms/controls/DateControlRenderer.vue";

import DateTimeControlRenderer from "@/core/json-forms/controls/DateTimeControlRenderer.vue";

import TimeControlRenderer from "@/core/json-forms/controls/TimeControlRenderer.vue";

import RendererConfigurations from "@/core/json-forms/renderer-configurations";

export const styles = mergeStyles(defaultStyles, {
  control: {
    root: "mb-2",
    input: "form-control",
    select: "form-select",
  },
});

export const renderers = Object.freeze([
  ...vanillaRenderers,
  { tester: RendererConfigurations.BooleanSwitchRenderer.tester, renderer: BooleanSwitchRenderer },
  { tester: RendererConfigurations.StringControlRenderer.tester, renderer: StringControlRenderer },
  { tester: RendererConfigurations.DefaultLayoutRenderer.tester, renderer: DefaultLayoutRenderer },
  { tester: RendererConfigurations.ArrayListRenderer.tester, renderer: ArrayListRenderer },
  { tester: RendererConfigurations.EnumControlRenderer.tester, renderer: EnumControlRenderer },
  { tester: RendererConfigurations.MultiStringControlRenderer.tester, renderer: MultiStringControlRenderer },
  { tester: RendererConfigurations.NumberControlRenderer.tester, renderer: NumberControlRenderer },
  { tester: RendererConfigurations.IntegerControlRenderer.tester, renderer: IntegerControlRenderer },
  { tester: RendererConfigurations.EnumOneOfControlRenderer.tester, renderer: EnumOneOfControlRenderer },
  { tester: RendererConfigurations.DateControlRenderer.tester, renderer: DateControlRenderer },
  { tester: RendererConfigurations.DateTimeControlRenderer.tester, renderer: DateTimeControlRenderer },
  { tester: RendererConfigurations.TimeControlRenderer.tester, renderer: TimeControlRenderer },
]);

export const defaultRenderers = Object.freeze([
  ...renderers,
  { tester: RendererConfigurations.DefaultStringControlRenderer.tester, renderer: DefaultStringControlRenderer },
]);
